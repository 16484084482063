function Facebook() {
	let height = window.innerHeight;
	let width = window.innerWidth;
	return (
		<div id='idFacebook' className='Facebook'>
			<div
				class='fb-page'
				data-href='https://www.facebook.com/movi.chorzow'
				data-tabs='timeline'
				data-width={width}
				data-height={height}
				data-small-header='false'
				data-adapt-container-width='false'
				data-hide-cover='false'
				data-show-facepile='true'>
				<blockquote
					cite='https://www.facebook.com/movi.chorzow'
					class='fb-xfbml-parse-ignore'>
					<a href='https://www.facebook.com/movi.chorzow'>
						MOVI taniec i ruch
					</a>
				</blockquote>
			</div>
		</div>
	);
}

export default Facebook;
