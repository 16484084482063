import przelewy from "../../../Image/Footer/Cooperation/Przelewy24_logo.png";
import PZUSport from "../../../Image/Footer/Cooperation/PZUSport.png";
import MEDICOVER from "../../../Image/Footer/Cooperation/MEDICOVER.png";
import FitProfit from "../../../Image/Footer/Cooperation/FitProfit.jpg";

function Cooperation() {
	return (
		<div className='cooperation-box'>
			<img src={przelewy} alt='przelewy 24'></img>
			<img src={PZUSport} alt='PZUSport'></img>
			<img src={MEDICOVER} alt='MEDICOVER'></img>
			<img src={FitProfit} alt='FitProfit'></img>
		</div>
	);
}

export default Cooperation;
