function Adres() {
	return (
		<div className='footer__adress'>
			<h1>Adres</h1>
			<ul>
				<li>MOVI taniec i ruch</li>
				<li>plac Świętego Jana 31B</li>
				<li>41-503 Chorzów</li>
			</ul>
		</div>
	);
}

export default Adres;
