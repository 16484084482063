function Kontakt() {
	return (
		<div className='footer__kontakt'>
			<h1>Kontakt</h1>
			<ul>
				<li>email: info@movitanieciruch.pl</li>
				<li>telefon: 576 606 566</li>
			</ul>
		</div>
	);
}

export default Kontakt;
